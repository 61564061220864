<template>
  <b-container>
    <div class="pt-2 pb-3 d-flex justify-content-center" v-if="loading">
      <b-spinner variant="primary" label="Загружаем данные..."></b-spinner>
      <div class="ml-3 align-self-center">Загружаем данные...</div>
    </div>
    <b-row>
      <b-col>
        <b-card no-body>
          <b-tabs small card v-on:input="onTab">
            <b-tab title="ЧГК" :active="isActive(0)">
              <b-card-text>
                <h3 class="pb-2">Наши игры ЧГК</h3>
                <OurChgkTable />
              </b-card-text>
            </b-tab>
            <b-tab title="Медийки" :active="isActive(1)">
              <b-card-text>
                <h3 class="pb-2">Наши медийки</h3>
                <MediykaTable :items="items"/>
              </b-card-text>
            </b-tab>
            <b-tab title="Информация" :active="isActive(2)">
              <b-card-text>
                <h3 class="pb-2">Информация по медийкам</h3>
                <MediykaHelp/>
              </b-card-text>
            </b-tab>
            <b-tab title="Статистика" :active="isActive(3)">
              <b-card-text>
                <h3 class="pb-2">Статистика по медийкам</h3>
                <MediykaStats :items="statItems" :total="stat" />
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from "moment";
import MediykaHelp from "../components/mediyka/MediykaHelp";
import MediykaTable from "../components/mediyka/MediykaTable";
import MediykaStats from "../components/mediyka/MediykaStats";
import OurChgkTable from "../components/our-chgk/OurChgkTable";

export default {
  components: {OurChgkTable, MediykaHelp, MediykaTable, MediykaStats},
  data: function () {
    return {
      loading: false,
      items: [],
      statItems: [],
      stats: {size: 0},
      stat: {gCount: 0, gPubCount: 0, gLostCount: 0, qCount: 0, qPubCount: 0, qgCount: 0, qgPubCount: 0}
    }
  },
  created() {
    this.loading = true;
    let mediykaUrl = "/api/data/mediyka.json?" + new Date().getTime();
    const promises = [mediykaUrl].map(url => fetch(url).then(r => r.json()));
    return Promise.all(promises).then(r => {
      const statMap = {};
      r[0].forEach((item) => {
        this.prepareItem(item);
        this.items.push(item);
        this.prepareStatItem(item, statMap);
      });
      this.statItems = Object.values(statMap);
      this.loading = false;
    });
  },
  methods: {
    onTab(i) {
      let hash = i === 0 ? 'chgk' : (i === 1 ? 'mediyka' : (i === 2 ? 'info' : 'stat'))
      location.hash = hash;
    },
    isActive(i) {
      if (i === 0) {
        return this.$route.hash === '#chgk';
      }
      if (i === 2) {
        return this.$route.hash === '#info';
      }
      if (i === 3) {
        return this.$route.hash === '#stat';
      }
      return this.$route.hash !== '#chgk'
          && this.$route.hash !== '#info'
          && this.$route.hash !== '#stat';
    },
    prepareItem(item) {
      item.quantity = 0;
      item.blocks = item.blocks || [];
      let authors = "";
      item.blocks.forEach(i => {
        item.quantity += i.quantity ? i.quantity : 0;
        if (i.date_pub) {
          i.datePub = new Date(i.date_pub).getTime();
          i.datePubText = moment(i.date_pub).format('YYYY.MM.DD');
          if (!item.datePub || item.datePub < i.datePub) {
            item.datePub = i.datePub;
            item.datePubText = i.datePubText;
          }
        }
        if (!item.status || item.status < i.status) {
          item.status = i.status;
        }
        item.description = item.description ? item.description : "";
        if (i.description) {
          item.description = item.description +
              (item.description.length > 0 ? " | " : "") + i.authors + ": " + i.description;
        }
        i.authorList = [];
        if (i.authors) {
          const urls = i.authors_info ? i.authors_info.split(", ") : [];
          i.authors.split(", ").forEach((a, index) => {
            i.authorList.push({name: a, url: urls.length > index ? urls[index] : null})
          });
          authors = authors + (authors.length > 0 ? ", " : "") + i.authors;
        }
      });

      if (item.date_start) {
        item.dateStart = new Date(item.date_start).getTime();
        item.dateStartText = moment(item.date_start).format('YYYY.MM.DD');
      }

      item.name = 'Вечер №' + item.num
          + (item.name ? ' | ' + item.name : '')
          + (item.closed === 1 ? ' | ' + authors : '');
      item.defaultSort = (item.status ? item.status : '00')
          + ';' + (item.datePub ? item.datePub : 1000000000000) + ';' + item.dateStart;
    },
    prepareStatItem(item, statMap) {
      item.blocks.forEach((b) => {
        const questions = b.quantity ? Math.round(b.quantity / b.authorList.length) : 0;

        this.stat.gCount++;
        if (b.quantity > 0) {
          this.stat.qCount = this.stat.qCount + b.quantity;
          this.stat.qgCount++;
        }

        if (b.file_url) {
          this.stat.gPubCount++;
          if (b.quantity > 0) {
            this.stat.qPubCount = this.stat.qPubCount + b.quantity;
            this.stat.qgPubCount++;
          }
        }

        b.authorList.forEach((a) => {
          if (!statMap[a.name]) {
            statMap[a.name] = {
              name: a.name, url: a.url, blocks: [],
              gCount: 0, qCount: 0, qgCount: 0,
              gPubCount: 0, qPubCount: 0, qgPubCount: 0,
              gLostCount: 0, percent: 0
            };
          }
          const s = statMap[a.name];
          s.blocks.push(b);
          s.gCount++;
          if (b.status < 10) {
            s.gLostCount++;
            this.stat.gLostCount++;
          }
          if (!s.url) {
            s.url = a.url;
          }
          if (questions > 0) {
            s.qCount = s.qCount + questions;
            s.qgCount++;
          }
          if (b.file_url) {
            s.gPubCount++;
            if (questions > 0) {
              s.qPubCount = s.qPubCount + questions;
              s.qgPubCount++;
            }
          }
          if (s.gCount !== s.gLostCount) {
            s.percent = Math.round(s.gPubCount * 100.0 / (s.gCount - s.gLostCount));
          } else {
            s.percent = 100;
          }
        });
      });
    }
  }
}
</script>
