<template>
  <div class="pb-5">
    <span v-for="(item, i) in items" :key="'chgk' + i">
      <b-row>
        <b-col md="4" class="pt-4">
          <b-img fluid :src="'/api/data/images/' + item.image"/>
        </b-col>
        <b-col class="pt-4">
          <h5 class="mt-0">{{ item.name }}</h5>
          <p class="small">
            {{ item.questionCount }} вопросов | заявленная сложность {{ item.levelPrediction }}
            <span v-if="item.level"> | реальная сложность {{ item.level }}</span>
            <br/>
            Редакторы:
            <span class="list-comma">
              <span v-for="(e,j) in item.editors" :key="'editor' + j">
                <a :href="e.url" target="_blank">{{ e.name }}</a>
              </span>
            </span><br/>
            Авторы:
            <span class="list-comma">
              <span v-for="(a,j) in item.authors" :key="'author' + j">
                <a :href="a.url" target="_blank">{{ a.name }}</a>
              </span>
            </span>
          </p>
          <p>
            <b-button v-if="item.packageUrl" size="sm" variant="success"  target="_blank" :href="item.packageUrl">
              <b><b-icon-download></b-icon-download> Скачать пакет вопросов</b>
            </b-button>
          </p>
          <p class="small">
            <a :href="item.synch.url">Синхрон</a> | <a :href="item.synch.registerUrl">Заявки</a><br/>
            {{ item.synch.start }} — {{ item.synch.end }} | {{ item.synch.price }}
          </p>
          <p class="small">
            <a :href="item.asynch.url">Асинхрон/онлайн</a> | <a :href="item.asynch.registerUrl">Заявки</a><br/>
            {{ item.asynch.start }} — {{ item.asynch.end }} | {{ item.asynch.price }}
          </p>
          <ul class="small" v-if="item.external">
            <li v-for="(e,i) in item.external" :key="'ext' + i">
              <a :href="e.url" target="_blank">{{ e.name }}</a>
            </li>
          </ul>
          <b-button v-if="item.resultHtml" size="sm" v-b-toggle.collapse-result>
            <b>Показать результат</b>
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="item.resultHtml">
        <b-col>
          <b-collapse id="collapse-result" class="mt-2">
            <p class="small" v-for="(r,i) in item.resultHtml" :key="'res' + i">
              <span v-html="r"></span>
            </p>
          </b-collapse>
        </b-col>
      </b-row>
    </span>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      items: []
    }
  },
  created() {
    let chgkUrl = "/api/data/chgk.json?" + new Date().getTime();
    const promises = [chgkUrl].map(url => fetch(url).then(r => r.json()));
    return Promise.all(promises).then(r => {
      this.items = r[0];
    });
  },
  methods: {}
}
</script>

<style>
span.list-comma span:not(:last-child)::after {
  content: ',  ';
}
</style>
