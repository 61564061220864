<template>
  <span>
    <b-table small striped hover responsive :sort-icon-left="true"
             :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
             :items="items" :fields="fields">
      <template #cell(name)="{item}">
        <span v-if="item.url">
          <a :href="item.url" target="_blank">
            <b-icon-person-fill></b-icon-person-fill>
          </a>
        </span>
        {{ item.name }}
      </template>
      <template #cell(gCount)="{item}">
        {{ item.gCount }}
        <span v-if="item.gLostCount > 0" class="text-muted small">
          - {{ item.gLostCount }} {{ declination(item.gLostCount, 'игра', 'игры', 'игр', 'игр') }}
        </span>
      </template>
      <template #cell(gPubCount)="{item}">
        {{ item.gPubCount }}
        <b-badge v-if="item.percent > 90" class="mr-1" variant="success">{{ item.percent }} %</b-badge>
        <b-badge v-if="item.percent > 50 && item.percent <= 90" class="mr-1" variant="warning">{{ item.percent }} %</b-badge>
        <b-badge v-if="item.percent <= 50" class="mr-1" variant="secondary">{{ item.percent }} %</b-badge>
      </template>
      <template #cell(qCount)="{item}">
        {{ item.qCount }}
        <span class="text-muted small">
          {{ item.qgCount }} {{ declination(item.qgCount, 'игра', 'игры', 'игр', 'игр') }}
        </span>
      </template>
      <template #cell(qPubCount)="{item}">
        {{ item.qPubCount }}
        <span class="text-muted small">
          {{ item.qgPubCount }} {{ declination(item.qgPubCount, 'игра', 'игры', 'игр', 'игр') }}
        </span>
      </template>
    </b-table>
    <div class="float-right text-right text-muted small pb-3">
      Игр открыто {{ total.gPubCount }} из доступных {{ total.gCount - total.gLostCount }} (плюс утеряно {{ total.gLostCount }}).<br/>
      Вопросов открыто {{ total.qPubCount }} ({{ total.qgPubCount }}) из {{ total.qCount }} ({{ total.qgCount }}).
    </div>
  </span>
</template>

<script>
export default {
  props: ['items', 'total'],
  data: function () {
    return {
      sortBy: 'qPubCount',
      sortDesc: true,
      fields: [
        {key: 'name', label: 'Автор', sortable: true},
        {key: 'gCount', label: 'Игры', sortable: true},
        {key: 'gPubCount', label: 'Открыты', sortable: true},
        {key: 'qCount', label: 'Вопросы', sortable: true},
        {key: 'qPubCount', label: 'Открыты', sortable: true}
      ]
    }
  }
}
</script>
