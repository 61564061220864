<template>
  <span>
    <div class="small clearfix">
      <b-form-checkbox id="show-closed" name="show-closed" v-model="hideClosed">
        {{hideClosed ? 'Показаны только игры с данными' : 'Показаны все игры'}}
      </b-form-checkbox>
    </div>
    <div class="small pb-3">
      Сортировать: <a href="javascript:void(0)" @click="sortBy = 'defaultSort'">по статусу</a>
      | <a href="javascript:void(0)" @click="sortBy = 'dateStarted'">по проведению</a>
      | <a href="javascript:void(0)" @click="sortBy = 'quantity'">по кол-ву вопросов</a>
    </div>
    <b-table small striped hover responsive
           :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
           :items="items" :fields="fields"
           :tbody-tr-class="rowClass" thead-class="hidden">
      <template #cell(info)="{item}">
        <span class="small mr-2">
          {{ item.dateStartText }}
          <span v-if="item.quantity > 0"> | <b-icon-question-circle title="Кол-во вопросов"></b-icon-question-circle> {{ item.quantity }}</span>
          | {{ item.name }}
        </span>
        <span class="text-muted small">
          <a v-if="item.notice_url" :href="item.notice_url" target="_blank" class="mr-2">
            <b-icon-chat-text></b-icon-chat-text>
          </a>
          <a v-if="item.event_url" :href="item.event_url" target="_blank" class="mr-2">
            <b-icon-calendar2-event></b-icon-calendar2-event>
          </a>
          <a v-if="item.photo_url" :href="item.photo_url" target="_blank">
            <b-icon-camera></b-icon-camera>
          </a>
        </span>
        <span class="float-right text-muted small text-right" v-if="item.status < 10">
          {{ getStatusText(item.status) }}
        </span>
        <br/>
        <span v-if="item.closed !== 1">
          <p class="small text-muted mb-0 mt-2" style="line-height: 1.2" v-for="(b,i) in item.blocks" :key="'block' + i">
            <span v-if="b.file_url" class="mr-2">
              <b-button size="sm" variant="success"  target="_blank"
                        :href="b.file_url" :title="'Добавлено: ' + b.datePubText">
                <b><b-icon-download></b-icon-download> Скачать</b>
              </b-button>
            </span>
            <b-icon v-if="b.status > 9 & b.status < 40 && !b.new"
                    class="mr-1" icon="exclamation-circle-fill"
                    :variant="getStatusStyle(b.status)"
                    :title="getStatusText(b.status)"></b-icon>
            <span class="list-comma">
              <span v-for="(a,j) in b.authorList" :key="'author' + j">
                <span v-if="a.url">
                  <a :href="a.url" target="_blank">
                    <b-icon-person-fill></b-icon-person-fill>
                  </a>
                </span>
                {{ a.name }}</span>
            </span>
            <span v-if="b.quantity"> | <b-icon-question-circle title="Кол-во вопросов"></b-icon-question-circle> {{ b.quantity }}</span>
            <span v-if="b.size_mb"> | ~{{ b.size_mb }} MB</span>
            <b-badge v-if="b.new" class="ml-1" variant="danger">Новый</b-badge>
          </p>
          <p class="small text-muted mb-0 mt-2" v-if="item.description">
            {{ item.description }}
          </p>
        </span>
      </template>
    </b-table>
  </span>
</template>

<script>
export default {
  props: ['items'],
  data: function () {
    return {
      hideClosed: true,
      sortBy: 'defaultSort',
      sortDesc: true,
      fields: [{key: 'info', label: 'Игра', sortable: false}]
    }
  },
  methods: {
    rowClass(item) {
      return 'mediyka' + (item.closed ? ' no-data' : '') + (this.hideClosed && item.closed === 1 ? ' hidden' : '');
    },
    getStatusStyle(status) {
      if (status === 30) {
        return 'success';
      }
      if (status === 25) {
        return 'warning';
      }
      if (status === 20) {
        return 'danger';
      }
      if (status === 10) {
        return 'secondary';
      }
      return 'dark';
    },
    getStatusText(status) {
      if (status === 40) {
        return 'Игра доступна по ссылке';
      }
      if (status === 30) {
        return 'В базе. Готово для открытия';
      }
      if (status === 25) {
        return 'В базе. Надо стандартизировать файлы';
      }
      if (status === 20) {
        return 'В базе. Надо восстановить ответы';
      }
      if (status === 10) {
        return 'Запросил у авторов';
      }
      if (status === 3) {
        return 'Игра потеряна: невозможно собрать (музыка игралась напрямую)';
      }
      if (status === 2) {
        return 'Игра потеряна: не найти файлы игры';
      }
      if (status === 1) {
        return 'Авторы отказались делиться игрой';
      }
      return '';
    }
  }
}
</script>

<style>
.hidden {
  display: none;
}
tr.mediyka td {
  padding: 10px 15px 10px 15px;
}
.no-data, .no-data a {
  color: #6c757d;
}
span.list-comma span:not(:last-child)::after {
  content: ',  ';
}
</style>
